import "bootstrap";
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import '@/channels';
import '@/controllers/application';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/scss/application/index.scss';


require('activeadmin_quill_editor')
require("@popperjs/core")

global.toastr = require("toastr")

Rails.start();
Turbolinks.start();
ActiveStorage.start();
require.context('@/images', true);